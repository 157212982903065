<template>
  <CCard>
    <CCardHeader>
      <TMessage content="User info" class="d-inline-flex mr-2" bold />
      <SMessageUserStatus :id="status" />
      <div class="float-right">
        <SButtonResendVerificationEmail v-if="!me.email_verified" />
        <SButtonResetPassword class="ml-2" />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow class="m-lg-1">
        <CCol sm="12" class="mb-3">
          <TInputUserName
            :value="me.name"
            label="Name"
            @update:value="data_update.name = $event"
            :savable="me.name != data_update.name"
            @click-save="changeName"
          />
        </CCol>
        <CCol sm="6" class="mb-3">
          <TInputEmail
            :value="me.email"
            label="Email address"
            :savable="false"
            :inputOptions="{ disabled: true }"
            @click-save="changeEmail"
          />
        </CCol>
        <CCol sm="6" class="mb-3">
          <TInputTel
            :value="me.tel"
            label="Tel"
            @update:value="data_update.tel = $event"
            :savable="me.tel != data_update.tel"
            @click-save="updateMe('tel', $event)"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      data_update: {},
    };
  },
  watch: {
    me: {
      immediate: true,
      handler(v) {
        this.data_update = { ...v };
      },
    },
  },
  methods: {
    changeEmail(email) {
      if (email !== this.me.email && email !== "") {
        this.$store.dispatch("auth.me.update_email", email);
      }
    },
    updateMe(key, value) {
      this.$store
        .dispatch("auth.me.update", {
          [key]: value,
        })
        .then(() => {
          this.$store.dispatch("auth.me.fetch");
        });
    },
    changeName(name) {
      this.$store
        .dispatch("auth.users.update", {
          id: this.me.id,
          attributes: {
            name: name,
          },
        })
        .then(() => {
          this.$store.dispatch("auth.me.fetch");
        });
    },
  },
  computed: {
    me() {
      return this.$store.getters["auth.me"];
    },
    status() {
      return this.me.email_verified ? "verified" : "unverified";
    },
  },
};
</script>
